<template>
  <Cascader
    filterable
    trigger="hover"
    :value="value"
    :data="carBSTree"
    :clearable="clearable"
    @on-change="$emit('change', $event)"
  />
</template>
<script>
export default {
  props: {
    value: { type: [Array, String, Number], default: "" },
    hasAll: { type: Boolean, default: true },
    clearable: { type: Boolean, default: false },
  },
  model: {
    prop: "value",
    event: "change",
  },
  created() {
    this.getDictionaryList();
  },
  data() {
    return {
      dictionary: [],
    };
  },
  methods: {
    getDictionaryList() {
      this.$http({
        url: this.$http.adornUrl("sys/dictionary/dictionaryTree"),
        method: "get",
      })
        .then((res) => {
          this.dictionary = res.data.data;
        })
        .catch((err) => {});
    },
  },
  computed: {
    carBSTree() {
      let tree = createTree(this.dictionary, null, "car_brand", this.hasAll);
      let index = this.hasAll ? 1 : 0;
      return (tree && tree[index] && tree[index].children) || [];
    },
  },
};
function createTree(data = [], pid, key, hasAll) {
  let array = hasAll ? [{ value: -1, label: "全部" }] : [];
  let found = data.filter((el) => (pid ? el.pid == pid : el.dataCode == key));
  found.forEach((el) => {
    const t = {
      value: el.dataName,
      label: el.dataName,
      children: createTree(data, el.id, null, hasAll),
    };
    if (hasAll ? t.children.length < 2 : !t.children.length) {
      delete t.children;
    }
    array.push(t);
  });
  return array;
}
</script>
